





















































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import controller from '@/app/ui/controllers/CampaignRamadhanController'
import { Validations } from 'vuelidate-property-decorators'
import { validationMixin } from 'vuelidate'
import { required, between } from 'vuelidate/lib/validators'
import Modal from '../components/Modal/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import Header from '../components/Header/index.vue'
import VueBottomSheet from '@webzlodimir/vue-bottom-sheet'
import ExpandIcon from '@/app/ui/assets/expand_icon.vue'
import CheckMarkIcon from '@/app/ui/assets/CampaignRamadhan/checkmark_icon.vue'
import ValidateFormIcon from '@/app/ui/assets/CampaignRamadhan/validate_from_icon.vue'
import ValidateTimeIcon from '@/app/ui/assets/CampaignRamadhan/validate_time_icon.vue'
import ValidateMaxDonateIcon from '@/app/ui/assets/CampaignRamadhan/validate_max_donate_icon.vue'

import TextInput from '../components/TextInput/index.vue'

@Component({
  mixins: [validationMixin],
  components: {
    Modal,
    Button,
    Header,
    VueBottomSheet,
    ExpandIcon,
    CheckMarkIcon,
    ValidateFormIcon,
    ValidateTimeIcon,
    ValidateMaxDonateIcon,
    TextInput,
  },
})
export default class FormPage extends Vue {
  controller = controller
  form = {
    senderName: '',
    senderPhone: '',
    address: '',
    city: '',
    district: '',
    subDistrict: '',
    packageType: {
      books: false,
      toys: false,
      clothes: false,
      rice: false,
    },
    totalWeight: 1.0,
  }

  isOpenModal = false
  modalType = ''
  selectedCityId = 0
  selectedDistrictId = 0
  selectedSubDistrictId = 0
  selectTypeList: string[] = []

  typeList: string[] = ['Mainan', 'Buku', 'Beras', 'Pakaian']

  cityList = [
    { id: 1, label: 'Jakarta Utara' },
    { id: 2, label: 'Jakarta Barat' },
    { id: 3, label: 'Jakarta Pusat' },
    { id: 4, label: 'Jakarta Selatan' },
    { id: 5, label: 'Jakarta Timur' },
    { id: 6, label: 'Kab. Kepulauan Seribu' },
    { id: 7, label: 'Bogor' },
    { id: 8, label: 'Kab. Bogor' },
    { id: 9, label: 'Depok' },
    { id: 10, label: 'Tangerang' },
    { id: 11, label: 'Kab. Tangerang' },
    { id: 12, label: 'Tangerang Selatan' },
    { id: 13, label: 'Bekasi' },
    { id: 14, label: 'Kab. Bekasi' },
  ]

  districList = [
    { id: 1, label: 'Cilincing', cityId: 1 },
    { id: 2, label: 'Kelapa Gading', cityId: 1 },
    { id: 3, label: 'Koja', cityId: 1 },
    { id: 4, label: 'Pademangan', cityId: 1 },
    { id: 5, label: 'Penjaringan', cityId: 1 },
    { id: 6, label: 'Tanjung Priok', cityId: 1 },
    { id: 7, label: 'Cengkareng', cityId: 2 },
    { id: 8, label: 'Grogol Petamburan', cityId: 2 },
    { id: 9, label: 'Taman Sari', cityId: 2 },
    { id: 10, label: 'Tambora', cityId: 2 },
    { id: 11, label: 'Kebon Jeruk', cityId: 2 },
    { id: 12, label: 'Kalideres', cityId: 2 },
    { id: 13, label: 'Palmerah', cityId: 2 },
    { id: 14, label: 'Kembangan ', cityId: 2 },
    { id: 15, label: 'Cempaka Putih', cityId: 3 },
    { id: 16, label: 'Gambir', cityId: 3 },
    { id: 17, label: 'Johar Baru', cityId: 3 },
    { id: 18, label: 'Kemayoran', cityId: 3 },
    { id: 19, label: 'Menteng', cityId: 3 },
    { id: 20, label: 'Sawah Besar', cityId: 3 },
    { id: 21, label: 'Senen', cityId: 3 },
    { id: 22, label: 'Tanah Abang', cityId: 3 },
    { id: 23, label: 'Cilandak', cityId: 4 },
    { id: 24, label: 'Jagakarsa', cityId: 4 },
    { id: 25, label: 'Kebayoran Baru', cityId: 4 },
    { id: 26, label: 'Kebayoran Lama', cityId: 4 },
    { id: 27, label: 'Mampang Prapatan', cityId: 4 },
    { id: 28, label: 'Pancoran', cityId: 4 },
    { id: 29, label: 'Pasar Minggu', cityId: 4 },
    { id: 30, label: 'Pesanggrahan', cityId: 4 },
    { id: 31, label: 'Setiabudi', cityId: 4 },
    { id: 32, label: 'Tebet', cityId: 4 },
    { id: 33, label: 'Cakung', cityId: 5 },
    { id: 34, label: 'Cipayung', cityId: 5 },
    { id: 35, label: 'Ciracas', cityId: 5 },
    { id: 36, label: 'Duren Sawit', cityId: 5 },
    { id: 37, label: 'Jatinegara', cityId: 5 },
    { id: 38, label: 'Kramat Jati', cityId: 5 },
    { id: 39, label: 'Makasar', cityId: 5 },
    { id: 40, label: 'Matraman', cityId: 5 },
    { id: 41, label: 'Pasar Rebo', cityId: 5 },
    { id: 42, label: 'Pulo Gadung', cityId: 5 },
    { id: 43, label: 'Kepulauan Seribu Utara', cityId: 6 },
    { id: 44, label: 'Kepulauan Seribu Selatan', cityId: 6 },
    { id: 45, label: 'Bogor Utara', cityId: 7 },
    { id: 46, label: 'Bogor Timur', cityId: 7 },
    { id: 47, label: 'Bogor Selatan', cityId: 7 },
    { id: 48, label: 'Bogor Barat', cityId: 7 },
    { id: 49, label: 'Bogor Tengah', cityId: 7 },
    { id: 50, label: 'Tanah Sareal', cityId: 7 },
    { id: 51, label: 'Babakan Madang', cityId: 8 },
    { id: 52, label: 'Bojonggede', cityId: 8 },
    { id: 53, label: 'Caringin', cityId: 8 },
    { id: 54, label: 'Cariu', cityId: 8 },
    { id: 55, label: 'Ciampea', cityId: 8 },
    { id: 56, label: 'Ciawi', cityId: 8 },
    { id: 57, label: 'Cibinong', cityId: 8 },
    { id: 58, label: 'Cibungbulang', cityId: 8 },
    { id: 59, label: 'Cigombong', cityId: 8 },
    { id: 60, label: 'Cigudeg', cityId: 8 },
    { id: 62, label: 'Cijeruk', cityId: 8 },
    { id: 63, label: 'Cileungsi', cityId: 8 },
    { id: 64, label: 'Ciomas', cityId: 8 },
    { id: 65, label: 'Cisarua', cityId: 8 },
    { id: 66, label: 'Ciseeng', cityId: 8 },
    { id: 67, label: 'Citeureup', cityId: 8 },
    { id: 68, label: 'Dramaga', cityId: 8 },
    { id: 69, label: 'Gunung Putri', cityId: 8 },
    { id: 60, label: 'Gunung Sindur', cityId: 8 },
    { id: 70, label: 'Jasinga', cityId: 8 },
    { id: 71, label: 'Jonggol', cityId: 8 },
    { id: 72, label: 'Kemang', cityId: 8 },
    { id: 73, label: 'Klapanunggal', cityId: 8 },
    { id: 74, label: 'Leuwiliang', cityId: 8 },
    { id: 75, label: 'Leuwisadeng', cityId: 8 },
    { id: 76, label: 'Megamendung', cityId: 8 },
    { id: 77, label: 'Nanggung', cityId: 8 },
    { id: 78, label: 'Pamijahan', cityId: 8 },
    { id: 79, label: 'Parung', cityId: 8 },
    { id: 80, label: 'Parung Panjang', cityId: 8 },
    { id: 81, label: 'Ranca Bungur', cityId: 8 },
    { id: 82, label: 'Rumpin', cityId: 8 },
    { id: 83, label: 'Sukajaya', cityId: 8 },
    { id: 84, label: 'Sukamakmur', cityId: 8 },
    { id: 85, label: 'Sukaraja', cityId: 8 },
    { id: 86, label: 'Tajur Halang', cityId: 8 },
    { id: 87, label: 'Tamansari', cityId: 8 },
    { id: 88, label: 'Tanjungsari', cityId: 8 },
    { id: 89, label: 'Tenjo', cityId: 8 },
    { id: 90, label: 'Tenjolaya', cityId: 8 },
    { id: 91, label: 'Beji', cityId: 9 },
    { id: 92, label: 'Bojongsari', cityId: 9 },
    { id: 93, label: 'Cilodong', cityId: 9 },
    { id: 94, label: 'Cimanggis', cityId: 9 },
    { id: 95, label: 'Cinere', cityId: 9 },
    { id: 96, label: 'Cipayung', cityId: 9 },
    { id: 97, label: 'Limo', cityId: 9 },
    { id: 98, label: 'Pancoran Mas', cityId: 9 },
    { id: 99, label: 'Sawangan', cityId: 9 },
    { id: 100, label: 'Sukmajaya', cityId: 9 },
    { id: 101, label: 'Tapos', cityId: 9 },
    { id: 102, label: 'Batuceper', cityId: 10 },
    { id: 103, label: 'Benda', cityId: 10 },
    { id: 104, label: 'Cibodas', cityId: 10 },
    { id: 105, label: 'Ciledug', cityId: 10 },
    { id: 106, label: 'Cipondoh', cityId: 10 },
    { id: 107, label: 'Jatiuwung', cityId: 10 },
    { id: 108, label: 'Karangtengah', cityId: 10 },
    { id: 109, label: 'Karawaci', cityId: 10 },
    { id: 110, label: 'Larangan', cityId: 10 },
    { id: 111, label: 'Neglasari', cityId: 10 },
    { id: 112, label: 'Periuk', cityId: 10 },
    { id: 113, label: 'Pinang', cityId: 10 },
    { id: 114, label: 'Tangerang', cityId: 10 },
    { id: 115, label: 'Balaraja', cityId: 11 },
    { id: 116, label: 'Cikupa', cityId: 11 },
    { id: 117, label: 'Cisauk', cityId: 11 },
    { id: 118, label: 'Cisoka', cityId: 11 },
    { id: 119, label: 'Curug', cityId: 11 },
    { id: 120, label: 'Gunung Kaler', cityId: 11 },
    { id: 121, label: 'Jambe', cityId: 11 },
    { id: 122, label: 'Jayanti', cityId: 11 },
    { id: 123, label: 'Kelapa Dua', cityId: 11 },
    { id: 124, label: 'Kemiri', cityId: 11 },
    { id: 125, label: 'Kresek', cityId: 11 },
    { id: 126, label: 'Kronjo', cityId: 11 },
    { id: 127, label: 'Kosambi', cityId: 11 },
    { id: 128, label: 'Legok', cityId: 11 },
    { id: 129, label: 'Mauk', cityId: 11 },
    { id: 130, label: 'Mekarbaru', cityId: 11 },
    { id: 131, label: 'Pagedangan', cityId: 11 },
    { id: 132, label: 'Pakuhaji', cityId: 11 },
    { id: 133, label: 'Panongan', cityId: 11 },
    { id: 134, label: 'Pasar Kemis', cityId: 11 },
    { id: 135, label: 'Rajeg', cityId: 11 },
    { id: 136, label: 'Sepatan', cityId: 11 },
    { id: 137, label: 'Sepatan Timur', cityId: 11 },
    { id: 138, label: 'Sindang Jaya', cityId: 11 },
    { id: 139, label: 'Solear', cityId: 11 },
    { id: 140, label: 'Sukadiri', cityId: 11 },
    { id: 141, label: 'Sukamulya', cityId: 11 },
    { id: 142, label: 'Teluknaga', cityId: 11 },
    { id: 143, label: 'Tigaraksa', cityId: 11 },
    { id: 144, label: 'Ciputat', cityId: 12 },
    { id: 145, label: 'Ciputat Timur', cityId: 12 },
    { id: 146, label: 'Pamulang', cityId: 12 },
    { id: 147, label: 'Pondok Aren', cityId: 12 },
    { id: 148, label: 'Serpong', cityId: 12 },
    { id: 149, label: 'Serpong Utara', cityId: 12 },
    { id: 150, label: 'Selu', cityId: 12 },
    { id: 151, label: 'Bantar Gebang', cityId: 13 },
    { id: 152, label: 'Bekasi Barat', cityId: 13 },
    { id: 153, label: 'Bekasi Selatan', cityId: 13 },
    { id: 154, label: 'Bekasi Timur', cityId: 13 },
    { id: 155, label: 'Bekasi Utara', cityId: 13 },
    { id: 156, label: 'Jatiasih', cityId: 13 },
    { id: 157, label: 'Jatisampurna', cityId: 13 },
    { id: 158, label: 'Medan Satria', cityId: 13 },
    { id: 159, label: 'Mustika Jaya', cityId: 13 },
    { id: 160, label: 'Pondok Gede', cityId: 13 },
    { id: 161, label: 'Pondok Melati', cityId: 13 },
    { id: 162, label: 'Rawalumbu', cityId: 13 },
    { id: 163, label: 'Babelan', cityId: 14 },
    { id: 164, label: 'Bojongmangu', cityId: 14 },
    { id: 165, label: 'Cabangbungin', cityId: 14 },
    { id: 166, label: 'Cibarusah', cityId: 14 },
    { id: 167, label: 'Cibitung', cityId: 14 },
    { id: 168, label: 'Cikarang Pusat', cityId: 14 },
    { id: 169, label: 'Cikarang Timur', cityId: 14 },
    { id: 170, label: 'Cikarang Selatan', cityId: 14 },
    { id: 171, label: 'Cikarang Utara', cityId: 14 },
    { id: 172, label: 'Karangbahagia', cityId: 14 },
    { id: 173, label: 'Kedungwaringin', cityId: 14 },
    { id: 174, label: 'Muara Gembong', cityId: 14 },
    { id: 175, label: 'Pebayuran', cityId: 14 },
    { id: 176, label: 'Serang Baru', cityId: 14 },
    { id: 177, label: 'Setu', cityId: 14 },
    { id: 178, label: 'Sukakarya', cityId: 14 },
    { id: 179, label: 'Sukatani', cityId: 14 },
    { id: 180, label: 'Sukawangi', cityId: 14 },
    { id: 181, label: 'Tambelan', cityId: 14 },
    { id: 182, label: 'Tambun Selatan', cityId: 14 },
    { id: 183, label: 'Tambun Utara', cityId: 14 },
    { id: 184, label: 'Tarumajaya', cityId: 14 },
  ]

  subDistrictList = []

  created() {
    this.form.senderName = this.$route.params.username
    this.form.senderPhone = this.$route.params.phonenumber
  }

  @Validations()
  validations() {
    return {
      form: {
        senderName: { required },
        senderPhone: { required },
        address: { required },
        city: { required },
        district: { required },
        totalWeight: { required, between: between(1.0, 5.0) },
      },
    }
  }

  get refInputCity() {
    return this.$refs.inputCity as any
  }

  get refInputDistrict() {
    return this.$refs.inputDistrict as any
  }

  get refInputSubDistrict() {
    return this.$refs.inputSubDistrict as any
  }

  get filteredDistrictList() {
    const filteredDistrict = this.districList.filter(
      (item) => item.cityId === this.selectedCityId
    )
    return filteredDistrict
  }

  get isMaxWeight() {
    return this.form.totalWeight > 5
  }

  get isDisableSubmit() {
    const isSelectPackageTYpe =
      this.form.packageType.books ||
      this.form.packageType.toys ||
      this.form.packageType.clothes ||
      this.form.packageType.rice

    return this.$v.form.$invalid || !isSelectPackageTYpe || this.isMaxWeight
  }

  private openModalCity() {
    this.refInputCity.open()
  }

  private handleSelectCity(id: number, name: string) {
    this.selectedCityId = id
    this.form.city = name
    this.form.district = ''
    this.refInputCity.close()
  }

  private openModalDistrict() {
    this.refInputDistrict.open()
  }

  private handleSelectDistrict(id: number, name: string) {
    this.selectedDistrictId = id
    this.form.district = name
    this.refInputDistrict.close()
  }

  private openModalSubDistrict() {
    this.refInputSubDistrict.open()
  }

  private handleSelectSubDistrict(id: number, name: string) {
    this.selectedSubDistrictId = id
    this.form.subDistrict = name
    this.refInputSubDistrict.close()
  }

  private handleSelectType(type: string) {
    if (this.selectTypeList.includes(type)) {
      this.selectTypeList = this.selectTypeList.filter((val) => val !== type)
    } else {
      this.selectTypeList.push(type)
    }
    this.form.packageType.books = this.findSelectedPackageType('Buku')
    this.form.packageType.toys = this.findSelectedPackageType('Mainan')
    this.form.packageType.clothes = this.findSelectedPackageType('Pakaian')
    this.form.packageType.rice = this.findSelectedPackageType('Beras')
  }

  private findSelectedPackageType(type: string) {
    return this.selectTypeList.find((item) => item === type) ? true : false
  }

  private handleCloseModal() {
    this.isOpenModal = false
  }

  private submitForm() {
    const { submitDonation, resetValidateData } = controller

    if (this.isDisableSubmit) {
      this.modalType = 'validateForm'
      this.isOpenModal = true
    } else {
      resetValidateData()
      submitDonation(this.form)
    }
  }

  @Watch('controller.validateData.isMaxDonate')
  onIsIsMaxDonateChanged(status: boolean) {
    if (status) {
      this.modalType = 'validateMaxDonate'
      this.isOpenModal = true
    }
  }

  @Watch('controller.validateData.isInvalidTime')
  onIsInvalidTimeChanged(status: boolean) {
    if (status) {
      this.modalType = 'validateTime'
      this.isOpenModal = true
    }
  }

  @Watch('controller.validateData.isValidated')
  onIsValidateChanged(status: boolean) {
    if (status && this.controller.orderId) {
      this.modalType = ''
      this.$router.push({
        name: 'CampaignRamadhanEndPage',
        params: {
          orderId: this.controller.orderId.toString(),
        },
      })
    }
  }
}
