

















































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  inheritAttrs: false, // We want to inherit props to <input/> not to <div/> wrapper
  components: {},
})
export default class TextInput extends Vue {
  @Prop({ default: 'text' }) private type!: string
  @Prop({ default: false }) private withCounter!: boolean
  @Prop({ default: 150 }) private maxCount!: number
  @Prop({ default: false }) private isError!: boolean
  @Prop({ default: '' }) private label!: string
  @Prop({ default: true }) private isShowLabel!: boolean
  @Prop({ default: false }) private isShowUnitLabel!: boolean
  @Prop({ default: false }) private isShowErrorMessage!: boolean
  @Prop({ default: '' }) private unitLabel!: string
  @Prop({ default: '' }) private errorMessage!: string

  get displayValue() {
    return this.$attrs.value
  }
  set displayValue(modifiedValue: string) {
    this.$emit('input', modifiedValue)
  }

  get countWords() {
    return this.$attrs.value ? this.$attrs.value.length : 0
  }

  get getErrorStatus() {
    return this.isError ? true : false
  }

  private onlyNumber($event: {
    keyCode: number
    which: number
    preventDefault: () => void
  }) {
    if (this.type === 'number') {
      const keyCode = $event.keyCode ? $event.keyCode : $event.which
      if (keyCode > 31 && (keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault()
      }
    }
  }
}
