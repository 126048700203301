




















































import { Component, Vue } from 'vue-property-decorator'
import Button from '@/app/ui/components/Button/index.vue'
import Header from '../components/Header/index.vue'
import Banner from '@/app/ui/assets/CampaignRamadhan/banner.vue'
import Courier from '@/app/ui/assets/CampaignRamadhan/courier-2.vue'

@Component({
  components: {
    Button,
    Header,
    Banner,
    Courier,
  },
})
export default class FormPage extends Vue {
  orderId = ''
  created() {
    this.orderId = this.$route.params.orderId
  }

  get mobileHomePageUrl() {
    return process.env.VUE_APP_MOBILE_HOME_PAGE_URL
  }
}
